import React from 'react'
import { Row, Button, Tabs, TabList, TabPanels, Tab, TabPanel } from 'src/ui'
import FuturesIndex from '../oms/futures/futures-index'
import MappingIndex from '../oms/mapping/mapping-index'
import { hasPermission } from 'src/utils/permissions'

type OfferManagementSystemProps = {
  company: Company
}

const availableCommodities = [
  'Ethanol',
  'Corn',
  'Soybean',
  'Soybean Oil',
  'Soybean Meal',
  'Spring Wheat',
  'Wheat',
  'Hard Red Wheat',
  'Live Cattle',
  'Feeder Cattle',
  'Lean Hogs',
  'Canola',
  'Oats',
  'Cotton',
  'Rice',
  'Canadian Dollar',
  'Crude Oil',
  'Heating Oil',
  'Milk Class III',
  'Milo',
  'US Dollar Index',
]

const OfferManagementSystem = ({ company }: OfferManagementSystemProps) => {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [showNewForm, setShowNewForm] = React.useState(false)

  return (
    <Tabs size="sm" onChange={(index) => setTabIndex(index)}>
      <Row justifyContent="space-between">
        <TabList>
          <Tab
            paddingBottom={2}
            borderBottom={`3px solid ${tabIndex === 0 ? '#2a69ac' : 'transparent'}`}
          >
            Futures
          </Tab>
          {/* <Tab
            paddingBottom={2}
            borderBottom={`3px solid ${tabIndex === 1 ? '#2a69ac' : 'transparent'}`}
          >
            Mapping
          </Tab> */}
        </TabList>
        {tabIndex === 1 && !showNewForm && (
          <Button
            size="sm"
            key="add"
            width="76px"
            colorScheme="primary"
            onClick={() => setShowNewForm(true)}
            isDisabled={!hasPermission('offer_management_edit')}
          >
            Add
          </Button>
        )}
      </Row>

      <TabPanels mt={3}>
        <TabPanel p={0}>
          <FuturesIndex company={company} availableCommodities={availableCommodities} />
        </TabPanel>
        <TabPanel>
          <MappingIndex
            availableCommodities={availableCommodities}
            company={company}
            setShowNewForm={setShowNewForm}
            showNewForm={showNewForm}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default OfferManagementSystem
